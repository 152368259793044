.loader-container {
    ._loading_overlay_overlay {
        z-index: 999999;
        // background-color: #444444;
        background: rgba(0, 0, 0, 0.7);
        color: rgb(255, 255, 255);
        overflow: hidden !important;
        transition: opacity 1000ms linear 0.3s;
        opacity: 1;
    }
}

.loader-overflow-none {
    overflow-y: hidden;
}

.loading-overlay {
    // overflow: hidden;
    min-height: 100vh;
    height: 100%;
    position: static !important;
}
.infinity-spin-loader {
    position: fixed;
    // overflow: hidden;
    top: 50%;
    left: 50%;
    z-index: 999999;
    transform: translate(-50%, -50%);
    // transition: all 3s ease-in-out;
}

// @media (max-width: 767px) {
//     .spinner {
//         top: 45%;
//         left: 45%;
//         transform: translate(-45%,-45%);
//     }
// }
